<template>
  <BaseModal :modal-name="setNewPasswordModal" :title="_T('@Change Password')">
    <form class="set-password" @submit.prevent="onSetPassword">
      <InputPassword
        v-model="form.password"
        :validate="v.password"
        :caption="_T('@New Password')"
        autocomplete-type="new-password"
      />
      <InputPassword
        v-model="form.confirmedPassword"
        :validate="v.confirmedPassword"
        :caption="_T('@Password confirmation')"
        autocomplete-type="new-password"
      />
      <ButtonPrimary
        button-width="268px"
        type="submit"
        :aria-label="_T('@Will confirm')"
        :disabled="setPasswordHandler.isHandled"
      >
        {{ _T("@Will confirm") }}
      </ButtonPrimary>
    </form>
  </BaseModal>
</template>

<script setup>
import useVuelidate from "@vuelidate/core";
import InputPassword from "~/modules/shared/inputs/InputPassword.vue";
import ButtonPrimary from "~/modules/shared/buttons/ButtonPrimary.vue";
import {
  successfulModal,
  unsuccessfulModal,
  useModalStore,
  setNewPasswordModal,
} from "~/modules/shared/modals/store/modalStore";
import {
  minLengthValidate,
  regExpValidate,
  requiredValidate,
  sameAsValidate,
} from "~/utils/validators";
import { useUserStore } from "~/store/userStore";
import { signFormStore } from "~/modules/account/store/accountFormStore";
import forbidden from "~/config/forbidden.config";
import { useSingletonHandler } from "~/uses/useSingletonHandler";
import { useAPI } from "~/uses/useMyFetch";
import BaseModal from "~/modules/shared/modals/BaseModal.vue";

const route = useRoute();

const formStore = signFormStore();
const modalStore = useModalStore();
const userStore = useUserStore();

const successfulData = {
  title: _T("@Change Password"),
  description: _T("@Password changed successfully"),
  isWithTimeout: true,
};

const unsuccessfulData = {
  title: _T("@Change Password"),
  description: _T("@Password changed unsuccessful"),
  isWithTimeout: false,
};

const form = reactive({
  password: "",
  confirmedPassword: "",
});

const rules = computed(() => ({
  password: {
    required: requiredValidate(),
    minLength: minLengthValidate("@Minimum password length", 6),
    validatePassword: regExpValidate(
      "@The password must contain at least one number",
      /\d/,
    ),
  },
  confirmedPassword: {
    required: requiredValidate(),
    sameAs: sameAsValidate("@Password mismatch", form.password),
  },
}));

const v = useVuelidate(rules, form);

function requestOptions() {
  return {};
}

const setPasswordHandler = useSingletonHandler(() =>
  useAPI(
    "/account/password/reset/complete",
    {
      method: "POST",
      body: {
        token: route.query.recovery,
        newPassword: form.password,
      },
    },
    // { isAuthorized: false },
  ),
);

async function onSetPassword() {
  if (formStore.isFormsReady()) {
    try {
      await setPasswordHandler.handle();

      userStore.fetch();

      modalStore.toggleModal(setNewPasswordModal);
      modalStore.toggleModal(successfulModal, successfulData);
    } catch (error) {
      if (forbidden.ACCOUNT_PASSWORD_RESET_INVALID_TOKEN === error.data.code) {
        modalStore.toggleModal(setNewPasswordModal);
        modalStore.toggleModal(unsuccessfulModal, unsuccessfulData);
      }
    }
  }
}

onMounted(() => {
  formStore.addForm({ v, requestOptions, id: "setNewPassword" });
});

onUnmounted(() => {
  formStore.resetForms();
});
</script>

<style lang="scss" scoped>
.set-password {
  @include flex-container(column, center, center);
  gap: 16px;
}
</style>
